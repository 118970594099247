import React from 'react'
import './PrivacyPolicy.css'

export const PrivacyPolicy = () => {
  return (
    <div className="container">
        <h1>Privacy Policy</h1>
        <h4> &nbsp; - Glamlush Technologies Private Limited</h4>
        <p>Last Updated: 26th June, 2023</p>

        <p>This Privacy Policy explains how Glamlush collects, uses, stores, and protects the personal
            information of individuals who use our mobile application for appointment
            booking at salons. We are committed to ensuring the privacy and security of your personal information. By
            using the App, you agree to the terms and practices described in this Privacy Policy.</p>

        <h2>1. Information We Collect:</h2>
        <p>We collect the following basic information from users to facilitate appointment booking and provide relevant
            salon recommendations:</p>

        <ul>
            <li>Name: We collect your name to personalize your experience and identify you within the App.</li>
            <li>Email: We collect your email address to communicate with you regarding your appointments and send
                important updates or notifications.</li>
            <li>Gender: We collect your gender information to enhance the accuracy of salon recommendations based on your
                preferences.</li>
            <li>Location: We collect your location data to display nearby salons and provide location-based search
                results.</li>
        </ul>

        <h2>2. Collection Methods:</h2>
        <p>We collect your personal information when you register an account on the App or when you voluntarily provide it
            during the appointment booking process. We may also collect location information through GPS, IP address, or
            other technologies with your consent.</p>

        <h2>3. Purpose of Data Collection:</h2>
        <p>We collect and use your personal information for the following purposes:</p>

        <ul>
            <li>Appointment Booking: To facilitate the booking of appointments at salons based on your preferences.</li>
            <li>Personalization: To personalize your experience within the App, including salon recommendations and
                content tailored to your preferences.</li>
            <li>Communication: To communicate with you regarding your appointments, updates, promotions, or other
                relevant information.</li>
            <li>Analytics and Improvements: To analyze user behavior and trends, improve our services, and enhance user
                satisfaction.</li>
        </ul>

        <h2>4. Legal Basis for Data Processing:</h2>
        <p>The legal basis for processing your personal information is your consent, which is obtained during the
            registration process and when you provide your personal information voluntarily.</p>

        <h2>5. Data Usage and Sharing:</h2>
        <p>We use your personal information solely for the purposes mentioned above and do not sell, rent, or trade it to
            third parties for their marketing purposes. However, we may share your information with:</p>

        <ul>
            <li>Salons: We share your basic information with salons to enable appointment booking and salon-related
                communication.</li>
            <li>Service Providers: We may engage third-party service providers to assist us in delivering our services,
                and they may have access to your personal information for that purpose.</li>
            <li>Legal Compliance: We may disclose your personal information if required by law, regulation, or legal
                process, or to protect our rights, property, or safety, or the rights, property, or safety of others.</li>
        </ul>

        <h2>6. User Rights:</h2>
        <p>You have the right to access, correct, delete, or restrict the processing of your personal information. You can
            exercise these rights by contacting us at the email address provided below. Please note that we may require
            certain information to verify your identity before responding to your requests.</p>

        <h2>7. Data Retention:</h2>
        <p>We retain your personal information for as long as necessary to fulfill the purposes mentioned in this Privacy
            Policy or as required by law. If you close your account, we will retain your information only for as long as
            necessary to comply with legal obligations, resolve disputes, and enforce our agreements.</p>

        <h2>8. Data Security:</h2>
        <p>We implement appropriate technical and organizational measures to protect your personal information from
            unauthorized access, loss, misuse, alteration, or disclosure. However, no method of transmission over the
            internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

        <h2>9. Updates to the Privacy Policy:</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We
            encourage you to review this Privacy Policy periodically. We will notify you of any material changes via
            email or through the App.</p>

        <div class="contact-info">
            <p>Contact Us:</p>
            <p>Email: glamspotdev@gmail.com</p>
        </div>
    </div>
  )
}
