import React from "react";
import "./hero_content.css";

const HeroContent = ({ isMobile }) => {
  return (
    <div style={{width: isMobile ? '100vw' : '45vw'}} className="hero-content-container">
      <b style={{ fontSize: '30px' }} className="hero-title">
        <b>Discover</b> your best salon experience
      </b>
      <b className="hero-subtitle">Book salon appointments hassle-free</b>
      {isMobile ? <DownloadVertical /> : <DownloadHorizontal />}
    </div>
  );
};

const DownloadVertical = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <br />
      <br />
      <a
        target="_"
        style={{ textDecoration: "none" }}
        href="https://play.google.com/store/apps/details?id=com.glamlush.app"
      >
        <button className="user-download-btn">Download for Android</button>
      </a>
      <br />
      <a
        style={{ textDecoration: "none" }}
        href="https://google.com"
        target="_"
      >
        <button className="user-download-btn">Download for Apple</button>
      </a>
    </div>
  );
};
const DownloadHorizontal = () => {
  return (
    <div className="user-download-container">
      <div className="info-text">
        <b className="primary">No. of salons</b>
        <b className="secondary">250</b>
      </div>
      <div className="vertical-line" />
      <div className="info-text">
        <b className="primary">Available in</b>
        <b className="secondary">Bangalore</b>
      </div>
      <div className="vertical-line" />
      <a
        target="_"
        href="https://play.google.com/store/apps/details?id=com.glamlush.app"
      >
        <button className="user-download-btn">Download for Android</button>
      </a>
      <div className="vertical-line" />
      <button className="user-download-btn">Download for Apple</button>
    </div>
  );
};

export default HeroContent;
