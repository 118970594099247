import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LandingPage } from "./pages/landing-page/LandingPage";
import { PrivacyPolicy } from "./pages/privacy-policy/PrivacyPolicy";
import { DeleteUserAccount } from "./pages/delete-user-account/DeleteUserAccount";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="delete-user-account" element={<DeleteUserAccount />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
