import React, { useState } from "react";
import "./landing_page.css";
import HeroContent from "./HeroContent";

export const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  window.onresize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }
  return (
    <div>
      <div className="header">
        <div style={{width: isMobile ? '100vw' : '45vw'}} className="header-action-container">
          <b className="logo">glamlush</b>
          {/* <b className="header-action">Home</b> */}
          {/* <b className="header-action">Process</b> */}
          {/* <b className="header-action">Reviews</b> */}
        </div>
      </div>
      {!isMobile ? <a href="mailto:support@glamlush.in"><button className="contact-btn">CONTACT US</button></a> : null}
      <div className="hero">
        <HeroContent isMobile={isMobile} />
        {!isMobile ? <div className="hero-img" /> : null}
      </div>
      <div className="vip-container">
        <div className="vip-text-container">
          <p style={{fontSize: isMobile ? '14px' : '20px'}} className="vip-text">
            Book your salon appointment – skip the queue, get VIP treatment!
          </p>
        </div>
        <div className="vip-action-container">
          <p style={{fontSize: isMobile ? '10px' : '16px'}} className="vip-desc">
            <b>Say Goodbye to Waiting</b>: Tired of standing in long salon queues? With
            our appointment booking platform, you can bid farewell to waiting
            times and step into your salon with no delays. 
          </p>
        </div>
      </div>
    </div>
  );
};
