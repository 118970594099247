import React, { useState } from 'react'
import './DeleteUserAccount.css'

export const DeleteUserAccount = () => {

  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleVerifyOtp = () => {
    if (otp === '123456') {
      // Call JavaScript function for successful OTP verification
      alert('OTP verification successful!');
    } else {
      setOtpError(true);
    }
  };

  const handleDeleteAccount = () => {
    // Call JavaScript function to delete the account
    alert('Account deleted!');
  };

  return (
    <div className="account-deletion">
      <h1>Account Deletion</h1>
      <div className="form-group">
        <label htmlFor="phone">Phone Number</label>
        <input
          type="tel"
          id="phone"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Enter your phone number"
          required
        />
      </div>
      <button onClick={() => setShowPopup(true)}>Send OTP</button>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>OTP Verification</h2>
            <input
              type="text"
              value={otp}
              onChange={handleOtpChange}
              placeholder="Enter OTP"
              required
            />
            {otpError && <p className="error-message">Incorrect OTP entered.</p>}
            <button onClick={handleVerifyOtp}>Verify OTP</button>
            {otp === '123456' && (
              <button className="delete-button" onClick={handleDeleteAccount}>
                Delete Account
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );

}
